* {
	margin: 0;
	padding: 0;
}

html {
	height: 100vh;
}

body {
	height: 100vh;
}

.container {
	padding: 0px 0px;
	margin: 0px;
	width: 100%;
}

.full {
	height: 100vh;
}

.App .navbar-brand {
	font-weight: bold;
}

.container_body {
	margin-top: 50px;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
