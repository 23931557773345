.Events .lander {
	text-align: center;
}

.Events .lander h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.Events .lander p {
	color: #999;
}

.ui.table td.negative,
.ui.table tr.negative {
	background: #ffe6e6 !important;
	color: #9f3a38 !important;
}

.ui.table td.positive,
.ui.table tr.positive {
	background: #ecffe5 !important;
	color: #2c662d !important;
}

.squareimg {
	position: relative;
}

.squareimg:after {
	content: '';
	display: block;
	padding-bottom: 100%;
}

.squareimg img {
	position: absolute; /* Take your picture out of the flow */
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; /* Make the picture taking the size of it's parent */
	width: 100%; /* This if for the object-fit */
	height: 100%; /* This if for the object-fit */
	object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
	object-position: center;
}
