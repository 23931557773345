@media all and (min-width: 480px) {
	.CreateEvent {
		padding: 30px 0;
		height: 100%;
	}

	.CreateEvent form {
		margin: 0 auto;
		max-width: 320px;
	}
	.react-datepicker-wrapper {
		display: flex !important;
	}
	.react-datepicker__input-container {
		display: flex;
		width: 100%;
	}
}
