@media all and (min-width: 480px) {
	.CreateUserProfile {
		padding: 30px 0;
	}

	.CreateUserProfile form {
		margin: 0 auto;
		max-width: 320px;
	}
}
